import React from 'react';
import PropTypes from 'prop-types';
import { BEM } from '@bikejs/react-bem';

import './index.scss';

const TITLE_TYPES = ['p1', 'p2', 'p3', 'p4'];

@BEM('paragraph')
export default class Paragraph extends React.PureComponent {
  static displayName = 'Paragraph';

  static propTypes = {
    size: PropTypes.oneOf(TITLE_TYPES),
    children: PropTypes.any.isRequired,
    theme: PropTypes.oneOf(['default']),
  };

  static defaultProps = {
    theme: 'default',
    size: 'p1',
  };

  render() {
    const { children, theme, size, tag, bem } = this.props;

    return React.createElement('p', {
      className: bem({ theme, size: size || tag }),
      children,
    })
  }
}

export const P1 = props => React.createElement(Paragraph, { ...props, size: 'p1' });
export const P2 = props => React.createElement(Paragraph, { ...props, size: 'p2' });
export const P3 = props => React.createElement(Paragraph, { ...props, size: 'p3' });
export const P4 = props => React.createElement(Paragraph, { ...props, size: 'p4' });
