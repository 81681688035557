import React from 'react';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { selectCompaniesList, selectUserInfo } from 'common/redux/cabinet/selectors';
import { selectUserData } from 'common/redux/energy/selectors';

import Title from 'components/Title';
import Button from 'components/Button';

import './index.scss';

@withRouter
@connect((state) => ({
  companies: selectCompaniesList(state),
  user: selectUserInfo(state),
  energyUser: selectUserData(state),
}))
@withTranslation()
@BEM('agreement-block')
export class AgreementBlock extends React.PureComponent {
  static displayName = 'AgreementBlock';

  async getAgreement() {
    const { user, energyUser } = this.props;
    window.open(energyUser && energyUser.contract ? energyUser.contract : user.contract);
    this.props.modal.close();
  }

  render() {
    const { companies, energyUser, match, t, bem } = this.props;
    const currentCompany = companies.find(item => item.id === Number(match.params.companyId));

    return (
      <section className={bem()}>
        <header className={bem('header')}>
          <Title size="h3">{t('Згенерувати договір співпраці')}</Title>
        </header>
        <article className={bem('main')}>
          <dl className={bem('data')}>
            <dt>{t('Продавець:')}</dt>
            <dd>ТОВ "ТАС Енергія Країни"</dd>
          </dl>
          <dl className={bem('data')}>
            <dt>{t('Покупець:')}</dt>
            <dd>{currentCompany.name}</dd>
          </dl>
        </article>
        <footer className={bem('footer')}>
          <Button color="orange" onClick={() => this.getAgreement()}>{t('Отримати')}</Button>
        </footer>
      </section>
    );
  }
}
